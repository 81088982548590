import { render, staticRenderFns } from "./OurPerks.vue?vue&type=template&id=e5680f3c"
import script from "./OurPerks.vue?vue&type=script&lang=js"
export * from "./OurPerks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockHeading: require('/home/rohit/Documents/GitHub/aibuddha-website/components/BlockHeading.vue').default})
