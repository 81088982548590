
import AOS from 'aos'
import 'aos/dist/aos.css'
export default{
    name: "ProductCard",
    props: {
        PRODUCTS: Array,
    },
    methods:{
        openUrl: (url) => {
            window.open(url, '_blank').focus();
        }
    },
    mounted() {
      AOS.init()
    },
}
