
import { CONSTANTS } from "../constants";

export default {
  name: "WorkInsights",
  data: () => ({
    WORK_INSIGHTS: CONSTANTS.WORK_INSIGHTS,
  }),
  mounted() {
    window.addEventListener("scroll", countUp);

    function countUp() {
      const element = document.querySelector("#wi");
      const position = element?.getBoundingClientRect();

      if (position?.top >= 0 && position?.bottom <= window.innerHeight) {
        window.removeEventListener("scroll", countUp);

        const valueDisplays = document.querySelectorAll(".count");
        valueDisplays.forEach((valueDisplay) => {
          const interval =
            parseInt(valueDisplay.getAttribute("data-duration")) * 1000;
          let startValue = parseInt(valueDisplay.getAttribute("data-start"));
          const endValue = parseInt(valueDisplay.getAttribute("data-end"));
          const delay = Math.floor(interval / (endValue - startValue));
          const counter = setInterval(function () {
            startValue += 1;
            valueDisplay.textContent = startValue;
            if (startValue === endValue) {
              clearInterval(counter);
            }
          }, delay);
        });
      }
    }
  },
};
