
import { CONSTANTS } from '../constants';

export default {
    name: 'FeatureCarousel',
    data: ()=>{
        return {
            FEATURE_LIST: CONSTANTS.FEATURE_CAROUSEL_LIST
        }
    }
}
