
import { CONSTANTS } from '../constants';
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
    name: "OurSolutions",
    data: () => {
        return {
            buttonText: "Learn More",
            SOLUTIONS: CONSTANTS.SOLUTIONS
        };
    },
    mounted() {
      AOS.init()
    },
}
