
import { Fragment } from 'vue-fragment'
export default {
    name: "Testimonials",
    components: { Fragment },
    props:{
        REVIEWS:Array
    },
    data(){
      return {
        cardWidth: 400,
        marqueePaused:false,
        hasShade:true,
        overflow:'hidden'
      }
    },
    mounted(){
      if (window.innerWidth < 600) {
        this.hasShade = false;
        this.overflow = 'visible'        
      }
    }
}
