
import { CONSTANTS } from '../constants';
import AOS from 'aos'
import 'aos/dist/aos.css'

export default{
    name: "Features",
    data: () => {
        return {
            FEATURE_LIST_1 : CONSTANTS.FEATURE_LISTS.FEATURE_LIST_1,
            FEATURE_LIST_2 : CONSTANTS.FEATURE_LISTS.FEATURE_LIST_2,
        };
    },
    mounted() {
      AOS.init()
    },
}
