import { render, staticRenderFns } from "./Roadmap.vue?vue&type=template&id=2840f165&scoped=true"
import script from "./Roadmap.vue?vue&type=script&lang=js"
export * from "./Roadmap.vue?vue&type=script&lang=js"
import style0 from "./Roadmap.vue?vue&type=style&index=0&id=2840f165&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2840f165",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockHeading: require('/home/rohit/Documents/GitHub/aibuddha-website/components/BlockHeading.vue').default})
