import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e154c300"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingContentMain: require('/home/rohit/Documents/GitHub/aibuddha-website/components/LandingContentMain.vue').default,OurPerks: require('/home/rohit/Documents/GitHub/aibuddha-website/components/OurPerks.vue').default,WorkInsights: require('/home/rohit/Documents/GitHub/aibuddha-website/components/WorkInsights.vue').default,FeatureCarousel: require('/home/rohit/Documents/GitHub/aibuddha-website/components/FeatureCarousel.vue').default,OurSolutions: require('/home/rohit/Documents/GitHub/aibuddha-website/components/OurSolutions.vue').default,Roadmap: require('/home/rohit/Documents/GitHub/aibuddha-website/components/Roadmap.vue').default,Features: require('/home/rohit/Documents/GitHub/aibuddha-website/components/Features.vue').default,Showcase: require('/home/rohit/Documents/GitHub/aibuddha-website/components/Showcase.vue').default,Testimonials: require('/home/rohit/Documents/GitHub/aibuddha-website/components/Testimonials.vue').default,FAQS: require('/home/rohit/Documents/GitHub/aibuddha-website/components/FAQS.vue').default,MidBanner: require('/home/rohit/Documents/GitHub/aibuddha-website/components/MidBanner.vue').default})
