
import { CONSTANTS } from '../constants';
export default{
    name: "OurPerks",
    data: () => {
        return {
            PERK_DATA: CONSTANTS.PERK_DATA
        };
    },
}
